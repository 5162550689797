import React from 'react';
import config from '../../config';
import { Helmet } from 'react-helmet';
import Carousel from '../Carousel';
import { Container } from 'reactstrap';

const Home = props => {
  console.log(props);
  return (
    <Container fluid>
      <Helmet>
        <title>{config.siteTitle} </title>
      </Helmet>
      <Carousel
        items={props.slides} />
    </Container>
  );
};

export default Home;
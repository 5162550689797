import React from 'react';
import {connect} from 'react-redux';
import ContactPage from './ContactPage';
import {sendMessage} from '../../modules/contactForm';

class ContactPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaConfirmed: false,
    };
  }

  onRecaptchaChange = (value) => {
    this.setState({
      recaptchaConfirmed: value,
    });
  };

  formSubmit = (e) => {
    e.preventDefault();
    if(this.state.recaptchaConfirmed) {
      this.props.sendMessage(new FormData(e.target));
    } else {
      this.setState({ recaptchaWarning: true })
    }
  };

  render() {
    return (
      <ContactPage
        onRecaptchaChange={this.onRecaptchaChange}
        recaptchaConfirmed={this.state.recaptchaConfirmed}
        onSubmit={this.formSubmit}
        isLoading={this.props.isLoading}
        recaptchaWarning={this.state.recaptchaWarning}
        error={this.props.error}
        success={this.props.success}
      />
    )
  }
}

const mapStateToProps = state => ({
  error: state.contactForm.error,
  isLoading: state.contactForm.isLoading,
  success: state.contactForm.success,
});

const mapActionsToProps = dispatch => ({
  sendMessage: (data) => dispatch(sendMessage(data)),
});

export default connect(mapStateToProps, mapActionsToProps)(ContactPageContainer);
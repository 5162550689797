import React from 'react'
import { connect } from 'react-redux'
import Home from './Home';
import {getCarouselSlides} from '../../modules/home';
import Loader from '../Loader/index';

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    this.props.getCarouselSlides();
  }
  
  render() {
    const { carouselIsLoading, slides } = this.props;

    if(carouselIsLoading) {
      return <Loader/>
    }

    return (
      <Home
        slides={slides}
      />
    )
  }
}


const mapStateToProps = state => ({
  carouselIsLoading: state.home.carouselIsLoading,
  slides: state.home.slides,
});

const mapActionsToProps = dispatch => ({
  getCarouselSlides: () => dispatch(getCarouselSlides()),
});


export default connect(
  mapStateToProps,
  mapActionsToProps
)(HomeContainer)

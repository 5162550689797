import config from '../config';

export const REQUEST_CAROUSEL_START = "REQUEST_CAROUSEL_START";
export const REQUEST_CAROUSEL_SUCCESS = "REQUEST_CAROUSEL_SUCCESS";
export const REQUEST_CAROUSEL_ERROR = "REQUEST_CAROUSEL_ERROR";

export const requestCarouselStart = () => ({
  type: REQUEST_CAROUSEL_START,
  payload: {},
});

export const requestCarouselSuccess = (slides) => ({
  type: REQUEST_CAROUSEL_SUCCESS,
  payload: slides,
});

export const requestCarouselError = (error) => ({
  type: REQUEST_CAROUSEL_ERROR,
  payload: error,
});

const initialState = {
  slides: [],
  carouselIsLoading: true,
};

export function getCarouselSlides() {
  return function (dispatch) {
    dispatch(requestCarouselStart());

    return fetch(`${config.apiUrl}/carousel`)
      .then(
        r => r.json(),
        error => dispatch(requestCarouselError(error))
      )
      .then( slides => {
        dispatch(requestCarouselSuccess(slides))
      })
  }
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case REQUEST_CAROUSEL_START:
      return Object.assign({}, state, {
        carouselIsLoading: true,
      });

    case REQUEST_CAROUSEL_ERROR:
      return Object.assign({}, state, {
        carouselIsLoading: false,
      });

    case REQUEST_CAROUSEL_SUCCESS:
      return Object.assign({}, state, {
        slides: action.payload,
        carouselIsLoading: false,
      });

    default:
      return state;
  }
};

export default reducer;
import React from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const CarouselSlide = styled.div`
  @media (min-width: 180px) { 
    background-image: url(${props => props.sourceImages.medium}); 
  }

  @media (min-width: 768px) { 
    background-image: url(${props => props.sourceImages.large}); 
  }
  background-position: center center;
  background-image: url(${props => props.sourceImages.large});
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  // height: 70vh;
  height: calc(100vh - 80px);
`;

const CarouselCustomCaption = styled(CarouselCaption)`
  && {
    display: block !important;
    width: 100%;
    background: linear-gradient(transparent, rgba(0,0,0,0.7));
    right: unset;
    left: unset;
    bottom: 0;
  }
`;

const CarouselCustomIndicators = styled(CarouselIndicators)`
  && {
    bottom: 5px;
  }
`;
class CarouselContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    const { items } = this.props;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.ID}
        >
          <Link to={`/portfolio/${item.category}/${item.post_name}`}>
            <CarouselSlide
              sourceImages={item.featured_image}
            />
          </Link>
          <CarouselCustomCaption captionText="" captionHeader={item.post_title}/>
        </CarouselItem>
      );
    });

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselCustomIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
    );
  }
}

export default CarouselContainer
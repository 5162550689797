import config from '../config';

export const REQUEST_CATEGORY_START = "REQUEST_CATEGORY_START";
export const REQUEST_CATEGORY_SUCCESS = "REQUEST_CATEGORY_SUCCESS";
export const REQUEST_CATEGORY_ERROR = "REQUEST_CATEGORY_ERROR";
export const CLEAR_CATEGORY_ITEMS = "CLEAR_CATEGORY_ITEMS";

export const requestCategoryStart = () => ({
  type: REQUEST_CATEGORY_START,
  payload: {},
});

export const requestCategorySuccess = (items) => ({
  type: REQUEST_CATEGORY_SUCCESS,
  payload: items,
});

export const requestCategoryError = (error) => ({
  type: REQUEST_CATEGORY_ERROR,
  payload: error,
});

export const clearCategoryItems = () => ({
  type: CLEAR_CATEGORY_ITEMS,
  payload: {},
});

const initialState = {
  categoryItems: [],
  categoryIsLoading: true,
  categoryName: null,
  currentPage: 0,
  error: false,
  hasMore: false,
};

export function getCategoryItems(categoryName, page) {
  return function (dispatch) {
    dispatch(requestCategoryStart());

    return fetch(`${config.apiUrl}/portfolio/${categoryName}?page=${page}`)
      .then(
        r => r.json(),
        error => dispatch(requestCategoryError(error))
      )
      .then(categoryPage => {
        if(categoryPage.error) {
          dispatch(requestCategoryError(categoryPage.error));
        } else {
          dispatch(requestCategorySuccess(categoryPage));
        }
      })
  }
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case REQUEST_CATEGORY_START:
      return Object.assign({}, state, {
        categoryIsLoading: true,
        error: false,
      });
    case REQUEST_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        categoryName: action.payload.categoryName,
        categoryItems: [
          ...state.categoryItems,
          ...action.payload.categoryItems
        ],
        categoryIsLoading: false,
        currentPage: action.payload.currentPage,
        error: false,
        hasMore: action.payload.hasMore,
      });
    case REQUEST_CATEGORY_ERROR:
      return Object.assign({}, state, {
        categoryIsLoading: false,
        categoryItems: [],
        categoryName: null,
        error: true,
      });
    case CLEAR_CATEGORY_ITEMS:
      return Object.assign({}, state, {
        categoryItems: [],
        currentPage: 0,
      });
    default:
      return state;
  }
};

export default reducer;
import config from '../config';

export const REQUEST_BASIC_PAGE_START = "REQUEST_BASIC_PAGE_START";
export const REQUEST_BASIC_PAGE_SUCCESS = "REQUEST_BASIC_PAGE_SUCCESS";
export const REQUEST_BASIC_PAGE_ERROR = "REQUEST_BASIC_PAGE_ERROR";

export const requestBasicPageStart = () => ({
  type: REQUEST_BASIC_PAGE_START,
  payload: {}
});

export const requestBasicPageSuccess = (pageData) => ({
  type: REQUEST_BASIC_PAGE_SUCCESS,
  payload: pageData,
});

export const requestBasicPageError = (error) => ({
  type: REQUEST_BASIC_PAGE_ERROR,
  payload: error,
});

const initialState = {
  pageData: null,
  loading: true,
  error: false,
};


export function getBasicPageData(pageSlug) {
  return function (dispatch) {
    dispatch(requestBasicPageStart());

    return fetch(`${config.wpJson}/v2/pages?slug=${pageSlug}`)
      .then(
        r => r.json(),
        error => dispatch(requestBasicPageError(error))
      )
      .then( pageData => {
        if(pageData.length === 0) {
          dispatch(requestBasicPageError());
        } else {
          dispatch(requestBasicPageSuccess(pageData[0]));
        }
      })
  }
}
const reducer = (state = initialState, action) => {
  switch(action.type) {
    case REQUEST_BASIC_PAGE_START:
      return Object.assign({}, state, {
        loading: true,
        error: false,
      });
    case REQUEST_BASIC_PAGE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        pageData: action.payload,
        error: false,
      });
    case REQUEST_BASIC_PAGE_ERROR:
      return Object.assign({}, state, {
        loading: false,
        pageData: null,
        error: true,
      });
    default:
      return state;
  }
};

export default reducer;
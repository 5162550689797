import React from 'react';
import ErrorPage from './index';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
    });
    console.error(error);
  }

  render() {
    if(this.state.hasError) {
      return <ErrorPage hideLink={true}/>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
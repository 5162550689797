import React from 'react';
import PortfolioItemPage from './PortfolioItemPage';
import { connect } from 'react-redux';
import {getPortfolioDetails} from '../../modules/portfolioDetail';
import Lightbox from 'react-image-lightbox';
import Loader from '../Loader';
import ErrorPage from '../ErrorPage/index';
class PortfolioItemPageContainer extends React.Component {
  componentWillMount() {
    this.props.getPortfolioDetails(this.props.match.params.portfolioItem);
  }

  onMoveNextRequest = () => {
    const items = this.props.portfolioDetailItems;
    const selectedItem = parseInt(this.props.match.params.selectedItem);
    if(selectedItem < items.length - 1) {
      this.navigateToSlide(selectedItem + 1);
    }
  };

  onMovePrevRequest = () => {
    const selectedItem = parseInt(this.props.match.params.selectedItem);
    if(selectedItem > 0) {
      this.navigateToSlide(selectedItem - 1);
    }
  };

  navigateToSlide = (slideIdx) => {
    const { category, portfolioItem } = this.props.match.params;
    this.props.history.replace(`/portfolio/${category}/${portfolioItem}/${slideIdx}`)
  };

  onCloseRequest = () => {
    const { category, portfolioItem } = this.props.match.params;
    this.props.history.replace(`/portfolio/${category}/${portfolioItem}`)
  };

  render() {
    if(this.props.isLoading) {
      return <Loader />;
    }

    if(this.props.error) {
      return <ErrorPage />
    }
    const selectedImageIdx = this.props.match.params.selectedItem;
    const items = this.props.portfolioDetailItems;
    const data = this.props.portfolioDetailData;

    return (
      <React.Fragment>
        <PortfolioItemPage
          items={items}
          data={data}
          {...this.props}
        />

        {selectedImageIdx &&
          <Lightbox
            mainSrc={items[selectedImageIdx].guid}
            nextSrc={items[(selectedImageIdx + 1) % items.length].guid}
            prevSrc={items[(selectedImageIdx + items.length - 1) % items.length].guid}
            onMoveNextRequest={this.onMoveNextRequest}
            onMovePrevRequest={this.onMovePrevRequest}
            onCloseRequest={this.onCloseRequest}
            animationDisabled={true}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.portfolioDetail.portfolioDetailIsLoading,
  portfolioDetailData: state.portfolioDetail.portfolioDetailData,
  portfolioDetailItems: state.portfolioDetail.portfolioDetailItems,
  error: state.portfolioDetail.error,
});

const mapActionsToProps = dispatch => ({
  getPortfolioDetails: portfolioId => dispatch(getPortfolioDetails(portfolioId)),
});

export default connect(mapStateToProps, mapActionsToProps)(PortfolioItemPageContainer);
import React from 'react';
import BasicPage from './BasicPage';
import {getBasicPageData} from '../../modules/basicPage';
import Loader from '../Loader/index';
import {connect} from 'react-redux';

class BasicPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageContent: null,
    }
  }
  componentWillMount() {
    this.props.getPageData();
  }
  render() {
    if(this.props.isLoading) {
      return <Loader />
    }

    return (
      <BasicPage
        data={this.props.pageData}
      />
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.basicPage.loading,
  pageData: state.basicPage.pageData,
});

const mapActionsToProps = (dispatch, ownProps) => ({
  getPageData: () => dispatch(getBasicPageData(ownProps.pageSlug)),
});

export default connect(mapStateToProps, mapActionsToProps)(BasicPageContainer);
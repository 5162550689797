import React from 'react';
import styled from 'styled-components';

const VideoPlayer = styled.video`
  height: 80vh;
  outline: none;
`;

function VideoPost(props) {
  return (
    <React.Fragment>
      <VideoPlayer width="100%" src={props.portfolioDetailItems[0].url} controls></VideoPlayer>
    </React.Fragment>
  );
}

export default VideoPost;
import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const Wrapper = styled.div`
  width: 260px;
  height: calc(100% - 50px);
  position: fixed;
  top: 50px;
  left:${props => props.isOpen ? '0%' : '-40%'};
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out all;
  z-index: 90;
  background-color: white;
  
  @media (max-width: 576px) { 
    width: 100%; 
    left:${props => props.isOpen ? '0%' : '-100%'};
  }
`;

const Backdrop = styled.div`
  position: fixed;
  opacity: ${ props => props.isOpen ? `1` : `0`};
  content: '';
  left: 0;
  top: 50px;
  width: 100vw;
  height: calc(100vh - 50px);
  background-color: rgba(0,0,0,0.6);
  transition: 0.3s ease-in-out opacity;
  pointer-events: ${ props => props.isOpen ? 'all' : 'none'}
  z-index: 50;
`;

const NavigationWrapper = styled.ul`
  list-style-type: none;
  left: ${props => props.active ? '0' : '-100%' }
  transition: 0.3s ease-in-out all;
  position: absolute;
  z-index: 99;
  background-color: white;
`;

const NavigationItem = styled.li`
  padding: 6px 5px;
  margin: 0;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  cursor: pointer;
  
  @media (max-width: 576px) { 
     padding: 10px 5px;
  }
  
  a, a:link, a:hover, a:active, a:visited {
    text-decoration: none;
    color: inherit;
  }
`;
const Menu = props => (
  <React.Fragment>
    <Backdrop isOpen={props.isOpen}
              onClick={props.isOpen ? props.toggleNavigation : null}
    />

    <Wrapper
      isOpen={props.isOpen}
    >
      <NavigationWrapper active={!props.viewingSubpages}>
        {props.pages.map( page => {
          return (
            <NavigationItem key={page.ID}>
              <Link
                to={page.children.length <= 0 ? page.slug : '#'}
                onClick={page.children.length > 0 ? props.toggleSubpages : props.toggleNavigation }
              >{page.title}</Link>
            </NavigationItem>
          );
        })}
      </NavigationWrapper>

      <NavigationWrapper active={props.viewingSubpages}>
        <NavigationItem onClick={props.toggleSubpages}>go back</NavigationItem>
        {props.pages[props.selectedSubpageIndex].children.map(subpage => (
          <NavigationItem key={subpage.ID}>
            <Link
              to={subpage.slug}
              onClick={props.toggleNavigation}
            >{subpage.title}</Link>
          </NavigationItem>
        ))}
      </NavigationWrapper>
    </Wrapper>
  </React.Fragment>
)

export default Menu;
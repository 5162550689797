import React from 'react';
import CategoryPage from './CategoryPage';
import {clearCategoryItems, getCategoryItems} from '../../modules/portfolio';
import {connect} from 'react-redux';
import ErrorPage from '../ErrorPage';

class CategoryPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
    };
  }
  componentWillReceiveProps(np) {
    if(np.match.params.category !== this.props.match.params.category) {
      this.getInitialLoad(np.match.params.category);
    }
  }

  componentWillMount() {
    this.getInitialLoad(this.props.match.params.category);
  }

  getInitialLoad(categoryName) {
    this.props.clearCategoryItems();
    this.fetchMore(0, categoryName);
  }


  fetchMore = (page, categoryName) => {
    if(!categoryName) {
      categoryName = this.props.match.params.category;
    }
    if(page === 0){
      this.props.getCategoryItems(categoryName, page);
    } else {
      this.props.getCategoryItems(categoryName, this.props.currentPage + 1);
    }
  };

  render() {
    const { categoryIsLoading, categoryItems, categoryName, location, error, hasMore } = this.props;
    const { fetchMore } = this;

    if(error) {
      return <ErrorPage />
    }

    if(categoryName) {
      return (
        <CategoryPage
          categoryName={categoryName}
          items={categoryItems}
          location={location}
          isLoading={categoryIsLoading}
          fetchMore={fetchMore}
          hasMore={hasMore}
        />
      )
    }
    return null;
  }
}

const mapStateToProps = state => ({
  categoryName: state.portfolio.categoryName,
  categoryIsLoading: state.portfolio.categoryIsLoading,
  categoryItems: state.portfolio.categoryItems,
  currentPage: state.portfolio.currentPage,
  error: state.portfolio.error,
  location: state.router.location,
  hasMore: state.portfolio.hasMore,
});

const mapDispatchToProps = dispatch => ({
  getCategoryItems: (categoryName, page) => dispatch(getCategoryItems(categoryName, page)),
  clearCategoryItems: () => dispatch(clearCategoryItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPageContainer);

import React from 'react';
import {Container} from 'reactstrap';
import {Helmet} from 'react-helmet';
import config from '../../config';

const BasicPage = (props) => (
  <Container>
    <Helmet>
      <title>{config.siteTitle}  - {props.data.title.rendered}</title>
    </Helmet>
    <h1>{props.data.title.rendered}</h1>

    <div dangerouslySetInnerHTML={{__html: props.data.content.rendered}} />
  </Container>
);

export default BasicPage;
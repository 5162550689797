import config from '../config';

export const NAVIGATION_TOGGLE = "NAVIGATION_TOGGLE";
export const REQUEST_PAGES_START = "REQUEST_PAGES_START";
export const REQUEST_PAGES_SUCCESS = "REQUEST_PAGES_SUCCESS";
export const REQUEST_PAGES_ERROR = "REQUEST_PAGES_ERROR";

export const toggleNavigation = () => ({
  type: NAVIGATION_TOGGLE,
  payload: {}
});

export const requestPagesStart = () => ({
  type: REQUEST_PAGES_START,
  payload: {},
});

export const requestPagesSuccess = (pages) => ({
  type: REQUEST_PAGES_SUCCESS,
  payload: pages,
});

export const requestPagesError = (error) => ({
  type: REQUEST_PAGES_ERROR,
  payload: error,
});

const initialState = {
  isMenuOpen: false,
  pages: [],
};

export function getPages() {
  return function (dispatch) {
    dispatch(requestPagesStart());

    return fetch(`${config.apiUrl}/menu`)
      .then(
        r => r.json(),
        error => dispatch(requestPagesError(error))
      )
      .then(pages => {
        dispatch(requestPagesSuccess(pages))
      })
  }
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
      case NAVIGATION_TOGGLE:
        return Object.assign({}, state, {
          isMenuOpen: !state.isMenuOpen,
        });
      case REQUEST_PAGES_SUCCESS:
        return Object.assign({}, state, {
          pages: action.payload,
        });
      default:
        return state;
    }
};


export default reducer;
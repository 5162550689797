import config from '../config';

export const REQUEST_MESSAGE_START = "REQUEST_MESSAGE_START";
export const REQUEST_MESSAGE_SUCCESS = "REQUEST_MESSAGE_SUCCESS";
export const REQUEST_MESSAGE_ERROR = "REQUEST_MESSAGE_ERROR";


export const requestMessageStart = () => ({
  type: REQUEST_MESSAGE_START,
  payload: {},
});
export const requestMessageSuccess = () => ({
  type: REQUEST_MESSAGE_SUCCESS,
  payload: {},
});
export const requestMessageError = () => ({
  type: REQUEST_MESSAGE_ERROR,
  payload: {},
});

export function sendMessage(message) {
  return function (dispatch) {
    dispatch(requestMessageStart());

    return fetch(`${config.apiUrl}/contactForm`, {
      method: "POST",
      body: message,
    })
      .then(
        r => r.json(),
        error => dispatch(requestMessageError(error))
      )
      .then( contactForm => {
        if(contactForm.error) {
          dispatch(requestMessageError(contactForm.error));
        } else {
          dispatch(requestMessageSuccess());
        }
      })
  }
}
const initialState = {
  isLoading: false,
  error: false,
  success: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case REQUEST_MESSAGE_START:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
        success: false,
      });
    case REQUEST_MESSAGE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: false,
        success: true,
      });
    case REQUEST_MESSAGE_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: true,
        success: false,
      });
    default:
      return state;
  }
};

export default reducer;
import React from 'react';
import {Route, Switch} from 'react-router-dom'
import Home from '../Home'
import Header from '../Header';
import Menu from '../Menu';
import {getPages} from '../../modules/navigation';
import {mainLoadingStart, mainLoadingStop} from '../../modules/app';
import {connect} from 'react-redux';
import Loader from '../Loader';
import PortfolioItemPage from '../PortfolioItemPage';
import CategoryPage from '../CategoryPage';
import BasicPage from '../BasicPage';
import ContactPage from '../ContactPage';
import ErrorPage from '../ErrorPage/index';
import ErrorBoundary from '../ErrorPage/ErrorBoundary';

class App extends React.Component {
  componentDidMount() {
    // Load all required data for the current page
    this.props.mainLoadingStart();
    this.props
      .getPages()
      .then(() => this.props.mainLoadingStop())
  }

  render() {
    if(this.props.appIsLoading) {
      return (
        <Loader/>
      )
    }

    return (
      <ErrorBoundary>
        <div>
          <Header/>
          <Menu />
          <div style={{paddingTop: '60px'}}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" render={() => <BasicPage pageSlug="about" />} />
              <Route path="/contact" component={ContactPage} />
              <Route exact path="/portfolio/:category" component={CategoryPage} />
              <Route exact path="/portfolio/:category/:portfolioItem/:selectedItem?" component={PortfolioItemPage} />
              <Route exact path="*" component={ErrorPage} />
            </Switch>
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  appIsLoading: state.app.mainLoading,
  location: state.router.location.pathname,
});

const mapActionsToProps = dispatch => ({
  getPages: () => dispatch(getPages()),
  mainLoadingStart: () => dispatch(mainLoadingStart()),
  mainLoadingStop: () => dispatch(mainLoadingStop()),
});

export default connect(mapStateToProps, mapActionsToProps)(App);
import config from '../config';

export const REQUEST_PORTFOLIO_DETAIL_START = "REQUEST_PORTFOLIO_DETAIL_START";
export const REQUEST_PORTFOLIO_DETAIL_SUCCESS = "REQUEST_PORTFOLIO_DETAIL_SUCCESS";
export const REQUEST_PORTFOLIO_DETAIL_ERROR = "REQUEST_PORTFOLIO_DETAIL_ERROR";

export const requestPortfolioDetailStart = () => ({
  type: REQUEST_PORTFOLIO_DETAIL_START,
  payload: {},
});

export const requestPortfolioDetailSuccess = (items) => ({
  type: REQUEST_PORTFOLIO_DETAIL_SUCCESS,
  payload: items,
});

export const requestPortfolioDetailError = (error) => ({
  type: REQUEST_PORTFOLIO_DETAIL_ERROR,
  payload: error,
});

export function getPortfolioDetails(portfolioId) {
  return function (dispatch) {
    dispatch(requestPortfolioDetailStart());

    return fetch(`${config.apiUrl}/portfolio/detail/${portfolioId}`)
      .then(
        r => r.json(),
        error => dispatch(requestPortfolioDetailError(error))
      )
      .then(portfolioDetail => {
        if(portfolioDetail.error) {
          dispatch(requestPortfolioDetailError(portfolioDetail.error));
        } else {
          dispatch(requestPortfolioDetailSuccess(portfolioDetail))
        }
      })
  }
}
const initialState = {
  portfolioDetailItems: [],
  portfolioDetailIsLoading: true,
  portfolioDetailData: null,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case REQUEST_PORTFOLIO_DETAIL_START:
      return Object.assign({}, state, {
        portfolioDetailItems: [],
        portfolioDetailIsLoading: true,
        portfolioDetailData: {},
        error: false,
      });
    case REQUEST_PORTFOLIO_DETAIL_SUCCESS:
      const postItems = action.payload.post_content;
      delete action.payload.post_content;

      return Object.assign({}, state, {
        portfolioDetailItems: postItems,
        portfolioDetailIsLoading: false,
        portfolioDetailData: action.payload,
        error: false,
      });
    case REQUEST_PORTFOLIO_DETAIL_ERROR:
      return Object.assign({}, state, {
        portfolioDetailItems: [],
        portfolioDetailIsLoading: false,
        portfolioDetailData: {},
        error: true,
      });
    default:
      return state;
  }
};


export default reducer;
import React from 'react';
import styled from 'styled-components';
import image from './nono.gif';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import config from '../../config';

const Image = styled.img`
  width: 100%;
    
  @media (min-width: 576px) { 
    width: 40%;
  }
`;

const Wrapper = styled.div`
  text-align: center;
`;

const BackButton = styled(Link)`
  display: block;
  margin-top: 1em;
  color: inherit;
  text-decoration: none;
  margin-left: 40px;
  font-size: 1.4em;
  
  @media (max-width: 576px) { 
    margin-left: 0px;
  }
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;
const ErrorPage = (props) => (
  <Wrapper>
    <Helmet>
      <title>{config.siteTitle} </title>
    </Helmet>
    <h1>Nothing to do here, move along...</h1>
    <Image src={image} alt="not-found-splash"/>
    {!props.hideLink && <BackButton to="/">Go to homepage</BackButton>}
  </Wrapper>
);

export default ErrorPage;
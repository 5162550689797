import { combineReducers } from 'redux'
import navigation from './navigation';
import app from './app';
import home from './home';
import portfolio from './portfolio';
import portfolioDetail from './portfolioDetail';
import basicPage from './basicPage';
import contactForm from './contactForm';

export default combineReducers({
  app,
  navigation,
  home,
  portfolio,
  portfolioDetail,
  basicPage,
  contactForm,
})


import React from 'react';
import Header from './Header';
import {connect} from 'react-redux';
import {toggleNavigation} from '../../modules/navigation';

class HeaderContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
    }
  }

  render() {
    return <Header
      toggleMenu={this.props.toggleNavigation}
      isMenuOpen={this.props.isMenuOpen}
    />

  }
}

const mapStateToProps = state => ({
  isMenuOpen: state.navigation.isMenuOpen,
});

const mapDispatchToProps = dispatch => ({
  toggleNavigation: () => dispatch(toggleNavigation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
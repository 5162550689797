const config = {
  apiUrl: "https://mihaelacherciu.com/wp-json/mc",
  wpJson: "https://mihaelacherciu.com/wp-json/wp",
  recaptchaAPIKey: "6Ld8HXYUAAAAACtj-LkLgedrB92Bjw_DALUq3us8",
  siteTitle: "Mihaela Cherciu",
};

if (process.env.NODE_ENV === 'production') {
  config.apiUrl = "/wp-json/mc";
  config.wpJson = "/wp-json/wp";
}

export default config;
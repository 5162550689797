import React from 'react';
import Masonry from 'react-masonry-component';
import { Link } from 'react-router-dom'
import styled from 'styled-components';

const PortfolioItemImage = styled.img`
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const PortfolioItemTitle = styled.div`
  position: absolute;
  bottom: 0;
  padding: 2em;
  font-size: 1.3em;
  color: white;
  background: linear-gradient(transparent, rgba(0,0,0,0.4));
  width: 100%;
  opacity: 1;
  transition: 0.3s ease-in-out all;
  transform: translateY(100%);
`;

const PortfolioItem = styled(Link)`
  width: 33%;
  overflow: hidden;
  margin: 0.1em;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    opacity: 0;
    transition: 0.3s ease-in-out all;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    ${PortfolioItemTitle} {
      transform: translate(0%);
    }
  }
  
  
  @media (max-width: 768px) { 
    width: 49%;
  }
  
  @media (max-width: 576px) { 
    width: 100%;
  }
  
`;

function MasonryPhotoAlbum(props) {
  return (
    <Masonry>
      {props.items.map( (item, idx) => (
        <PortfolioItem
          key={item.ID}
          to={`/portfolio/${props.match.params.category}/${props.match.params.portfolioItem}/${idx}`}
        >
          {item.post_type === "attachment" && (
            <PortfolioItemImage
              srcSet={`${item.images.small} 300w, ${item.images.small} 350w,${item.images.large} 460w`}
              sizes="(max-width: 300px) 300px, (max-width: 350px) 350px, 460px"
              src={item.images.large}
            />
          )}

          {item.post_type === "attachment_video" && (
            <video src={item.url} />
          )}
        </PortfolioItem>
      ))}
    </Masonry>
  );
}

export default MasonryPhotoAlbum;
import React from 'react';
import { Container } from 'reactstrap';
import Masonry from 'react-masonry-component';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet/es/Helmet';
import config from '../../config';
import Loader from '../Loader/index';

const PortfolioItemImage = styled.img`
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const PortfolioItemTitle = styled.div`
  position: absolute;
  bottom: 0;
  padding: 2em;
  font-size: 1.3em;
  color: white;
  background: linear-gradient(transparent, rgba(0,0,0,0.4));
  width: 100%;
  opacity: 1;
  transition: 0.3s ease-in-out all;
  transform: translateY(100%);
`;

const PortfolioItem = styled(Link)`
  width: 33%;
  overflow: hidden;
  margin: 0.1em;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    opacity: 0;
    transition: 0.3s ease-in-out all;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    ${PortfolioItemTitle} {
      transform: translate(0%);
    }
  }
  
  @media (max-width: 768px) { 
    width: 49%;
  }
  
  @media (max-width: 576px) { 
    width: 100%;
  }
`;

const CategoryHeader = styled.h1`
  text-align: center;
`;

const LoadMore = styled.button`
  background: none;
  border: none;
  font-size: 1.3em;
  font-weight: bold;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  padding: 2em;
`;

const CategoryPage = props => (
  <Container fluid={true}>
    <Helmet>
      <title>{config.siteTitle}  - {props.categoryName}</title>
    </Helmet>
    <CategoryHeader>{props.categoryName}</CategoryHeader>
    <Masonry>
      {props.items.map( item => (
        <PortfolioItem key={`${item.ID}-${item.post_name}`} className="image-element-class" to={`${props.location.pathname}${item.post_name}`}>
          <PortfolioItemImage src={item.featured_image}/>
          <PortfolioItemTitle>{item.post_title}</PortfolioItemTitle>
        </PortfolioItem>
      ))}
    </Masonry>
    {props.isLoading && <Loader/>}
    {props.hasMore && <LoadMore onClick={props.fetchMore}>Load More</LoadMore>}

  </Container>
);


export default CategoryPage;

import React from 'react';
import MenuButton from './MenuButton';
import {Col, Row} from 'reactstrap';
import styled from 'styled-components';
import logo from './res/logo.jpg';
import facebook from './res/facebook.svg';
import instagram from './res/instagram.svg';
import {Link} from 'react-router-dom';

const Wrapper = styled.div`
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);
  z-index: 90;
  position: relative;
  margin-bottom: 1em;
  position: fixed;
  width: 100%;
  background-color: white;
`;

const MenuWrapper = styled(Col).attrs({lg: '4', md: '4', sm: '4', xs: '4'})`
  display: flex;
  align-item: center;
  justify-content: flex-start;
`;

const LogoWrapper = styled(Col).attrs({lg: '4', md: '4', sm: '4', xs: '4'})`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderRightSide = styled(Col).attrs({lg: '4', md: '4', sm: '4', xs: '4'})`
  font-size: 0.7em;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  ul {
    list-style-type: none;
    margin-bottom: 0;
    li {
      display: inline-block;
      margin-left: 0.3em;
    }
  }
`;

const SocialWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-flow: row;
  align-items: center;  
  padding-left: 0;
`;
const ContactWrapper = styled.ul`
  flex-flow: column;
  padding-left: 0;
`;
const ContactLI = styled.li`
  @media (max-width: 576px) { 
    display: none !important;
  }
`;
const ContactLabel = styled.span`
  @media (max-width: 768px) { 
    display: none !important;
  }
`
const Logo = styled.img`
  max-height: 50px;
`;

const Social = styled.img`
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 3px;
`;
const Header = props => (
  <Wrapper>
    <Row>
      <MenuWrapper lg="4">
        <MenuButton
          toggleMenu={props.toggleMenu}
          isActive={props.isMenuOpen}
        />
      </MenuWrapper>

      <LogoWrapper lg="4">
        <Link to="/"><Logo src={logo}/></Link>
      </LogoWrapper>

      <HeaderRightSide>
        <SocialWrapper>
          <li>
            <a href="https://www.instagram.com/mihaelacherciu/" target="_blank" rel="noopener noreferrer"><Social src={instagram} /></a>
          </li>
          <li>
            <a href="https://www.facebook.com/MihaelaCherciu.MakeupArtist/" target="_blank" rel="noopener noreferrer"><Social src={facebook} /></a>
          </li>
        </SocialWrapper>
        <ContactWrapper>
          <ContactLI>
            <ContactLabel>Mail:</ContactLabel>
            <span>mihaela.cherciu@hotmail.com</span>
          </ContactLI>
          <ContactLI>
            <ContactLabel>Phone: </ContactLabel>
            <span>+40735 559 397</span>
          </ContactLI>
        </ContactWrapper>
      </HeaderRightSide>
    </Row>
  </Wrapper>
);

export default Header;
import React from 'react';
import styled from 'styled-components';

const MenuButtonElement = styled.button`
  && {
    outline: none;
  }
`;

const MenuLabel = styled.span`
  position: relative;
  right: -40px;
`;

const MenuButton = props => (
  <MenuButtonElement
    className={`hamburger hamburger--vortex ${props.isActive ? "is-active" : ""}`}
    type="button"
    onClick={props.toggleMenu}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner"></span>
      <MenuLabel>Menu</MenuLabel>
    </span>
  </MenuButtonElement>
);

export default MenuButton;
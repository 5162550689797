import React from 'react';
import styled from 'styled-components';
import {Form, FormGroup, Input, Button, Label} from 'reactstrap';
import ReCAPTCHA from "react-google-recaptcha";
import config from '../../config';
import {Helmet} from 'react-helmet';

const Header = styled.h1`
  text-align: center;
`;

const Wrapper = styled.div`
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
  label {
    width: 100%;
  }
  
  @media (max-width: 576px) { 
    width: 95%; 
  }
`;

const RecaptchaWrapper = styled(FormGroup)`
  > div {
    > div {
      > div {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`;

const CenterWrapper = styled.div`
  text-align: center;
`;
const ContactPage = props => (
  <Wrapper>
    <Helmet>
      <title>{config.siteTitle}  - Contact</title>
    </Helmet>
    <Header>Contact</Header>

    <Form onSubmit={props.onSubmit}>
      <FormGroup>
        <Label>Name
          <Input type="input" name="name" required disabled={props.isLoading}/>
        </Label>
      </FormGroup>
      <FormGroup>
        <Label>Email
          <Input type="email" name="email" required disabled={props.isLoading}/>
        </Label>
      </FormGroup>
      <FormGroup>
        <Label>Message
          <Input type="textarea" name="message" required disabled={props.isLoading}/>
        </Label>
      </FormGroup>
      <RecaptchaWrapper>
        {props.recaptchaWarning && <p>Please confirm you're not a robot</p>}
        <ReCAPTCHA
          sitekey={config.recaptchaAPIKey}
          onChange={props.onRecaptchaChange}
        />
      </RecaptchaWrapper>
      <CenterWrapper>
        <Button type="submit" disabled={props.isLoading || !props.recaptchaConfirmed}>Send</Button>
      </CenterWrapper>
      <CenterWrapper>
        {props.error && <p>An error was encountered while sending your request!</p>}
        {props.success && <p>Request sent!</p>}
      </CenterWrapper>
    </Form>
  </Wrapper>
);

export default ContactPage;

export const MAIN_LOADING_START = "MAIN_LOADING_START";
export const MAIN_LOADING_STOP = "MAIN_LOADING_STOP";

export const mainLoadingStart = () => ({
  type: MAIN_LOADING_START,
  payload: {}
});

export const mainLoadingStop = () => ({
  type: MAIN_LOADING_STOP,
  payload: {},
});

const initialState = {
  mainLoading: true,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case MAIN_LOADING_START:
      return Object.assign({}, state, {
        mainLoading: true,
      });
    case MAIN_LOADING_STOP:
      return Object.assign({}, state, {
        mainLoading: false,
      });
    default:
      return state;
  }
};


export default reducer;
import React from 'react';
import { Container } from 'reactstrap';

import styled from 'styled-components';
import Helmet from 'react-helmet/es/Helmet';
import config from '../../config';
import MasonryPhotoAlbum from './MasonryPhotoAlbum';
import VideoPost from './VideoPost';

const CategoryHeader = styled.h1`
  text-align: center;
`;

const PortfolioItemPage = props => (
  <Container fluid={true}>
    <Helmet>
      <title>{config.siteTitle} - {props.data.post_title}</title>
    </Helmet>
    <CategoryHeader>{props.data.post_title}</CategoryHeader>
    { props.match.params.category !== "fashion-films" && <MasonryPhotoAlbum {...props} /> }
    { props.match.params.category === "fashion-films" && <VideoPost {...props} /> }

  </Container>
);


export default PortfolioItemPage;

import React from 'react';
import {toggleNavigation} from '../../modules/navigation';
import {connect} from 'react-redux';
import Menu from './Menu';

class MenuContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewingSubpages: false,
    }
  }

  toggleSubpages = () => {
    this.setState({ viewingSubpages: !this.state.viewingSubpages });
  };

  toggleNavigation = () => {
    this.props.toggleNavigation();
    this.setState({ viewingSubpages: false });
  };

  render() {
    const { isMenuOpen, pages } = this.props;

    return <Menu
      isOpen={isMenuOpen}
      toggleNavigation={this.toggleNavigation}
      pages={pages}
      toggleSubpages={this.toggleSubpages}
      viewingSubpages={this.state.viewingSubpages}
      selectedSubpageIndex={1}
    />
  }
}


const mapStateToProps = state => ({
  isMenuOpen: state.navigation.isMenuOpen,
  pages: state.navigation.pages,
});

const mapDispatchToProps = dispatch => ({
  toggleNavigation: () => dispatch(toggleNavigation()),
});


export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);